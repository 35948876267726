import React from 'react';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import logo from '../Assets/Images/LOGO-WHITE.svg';
import linkedin from '../Assets/Images/linkedin.svg';
import instagram from '../Assets/Images/instagram.svg';

export default function Footer() {
  const [css] = useStyletron();
  const footer = css({
    fontFamily: 'Barlow',
    fontSize: '24px',
    lineHeight: '29px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '0',
    background: 'black',
    color: 'white',
    padding: '80px 0px 80px 0px',
    '@media screen and (max-width: 599px)': {
      padding: '50px 22px',
    },
  });
  const footerColumn = css({
    width: '100%',
    overflow: 'hidden',
    '@media screen and (max-width: 599px)': {
      marginBottom: '30px',
    },
  });

  const footerColumnLast = css({
    marginLeft: '8.3vw',
    '@media screen and (max-width: 900px)': {
      marginLeft: '3vw',
    },
    '@media screen and (max-width: 599px)': {
      marginLeft: '0',
    },
  });
  const footerTitle = css({
    background: 'black',
    padding: '0 10px 0 0',
  });
  const footerH1 = css({
    fontFamily: 'Barlow',
    fontSize: '1.875rem',
    lineHeight: '1',
    fontStyle: 'normal',
    fontWeight: 400,
    marginTop: '0',
    width: '100%',
    textAlign: 'left',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '1px',
      background: 'white',
      marginLeft: '20px',
    },
    '@media screen and (max-width: 800px)': {
      fontSize: '1.25rem',
    },
    '@media screen and (max-width: 599px)': {
      fontSize: '1.875rem',
    },
  });

  const footerLink = css({
    width: '100% !important',
    fontFamily: 'Barlow',
    fontSize: '13px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    listStyle: 'none',
    padding: '0',
    '@media screen and (max-width: 599px)': {
      marginTop: '15px',
    },
  });

  const link = css({
    fontFamily: 'Barlow',
    color: 'white',
    textDecoration: 'none',
    fontSize: '22px',
    whiteSpace: 'nowrap',
    '@media screen and (max-width: 800px)': {
      fontSize: '18px',
    },
    '@media screen and (max-width: 599px)': {
      fontSize: '22px',
    },
  });

  const socialItem = css({
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    gap: '10px',
    marginBottom: '28px',
    ':last-child': {
      marginBottom: '0',
    },
  });

  const socialIcon = css({
    width: '30px',
  });

  return (
    <div className={footer}>
      <Grid
        overrides={{
          Grid: { style: { padding: '0px !important' } },
        }}>
        <Cell span={[12, 2, 3]}>
          <div className={footerColumn}>
            <a href="https://global-fashion-group.com">
              <img src={logo} style={{ maxWidth: 180 }} alt="" />
            </a>
          </div>
        </Cell>
        <Cell span={[12, 3, 5]}>
          <div className={footerColumn}>
            <h1 className={footerH1}>
              <span>MARKETS</span>
            </h1>
            <ul className={footerLink}>
              <li className={link}>
                <a href="https://global-fashion-group.com/markets/dafiti/">DAFITI</a>
              </li>
              <li className={link}>
                <a href="https://global-fashion-group.com/markets/zalora/">ZALORA</a>
              </li>
              <li className={link}>
                <a href="https://global-fashion-group.com/markets/the-iconic/">THE ICONIC</a>
              </li>
            </ul>
          </div>
        </Cell>
        <Cell span={[12, 3, 4]}>
          <div className={`${footerColumnLast}`}>
            <h1 className={footerH1}>
              <span className={footerTitle}>CONNECT WITH US</span>
            </h1>
            <a
              className={socialItem}
              href="https://www.instagram.com/dafiti/"
              target="_blank"
              rel="noopener noreferrer">
              <img className={socialIcon} src={instagram} alt="" />
              <span>@DAFITI</span>
            </a>
            <a
              className={socialItem}
              href="https://www.instagram.com/zalora/"
              target="_blank"
              rel="noopener noreferrer">
              <img className={socialIcon} src={instagram} alt="" />
              <span>@ZALORA</span>
            </a>
            <a
              className={socialItem}
              href="https://www.instagram.com/theiconicau/"
              target="_blank"
              rel="noopener noreferrer">
              <img className={socialIcon} src={instagram} alt="" />
              <span>@THEICONICAU</span>
            </a>
            <a
              className={socialItem}
              href="https://www.linkedin.com/company/global-fashion-group/"
              target="_blank"
              rel="noopener noreferrer">
              <img className={socialIcon} style={{ paddingBottom: 7 }} src={linkedin} alt="" />
              <span>GLOBAL FASHION GROUP</span>
            </a>
          </div>
        </Cell>
      </Grid>
      <Grid
        overrides={{
          Grid: { style: { padding: '0px !important', marginTop: '80px !important' } },
        }}>
        <Cell span={[8, 6, 8]}>
          <div
            className={css({
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '1.125rem',
            })}>
            <span>© Global Fashion Group 2025</span>
            {' | '}
            <a href="https://global-fashion-group.com/privacy-cookies-policy/">
              Privacy and Cookies Policies
            </a>
            {' | '}
            <a href="https://global-fashion-group.com/terms-of-use/">Terms of Use</a>
          </div>
        </Cell>
        <Cell span={[8, 2, 4]}>
          <div
            className={css({
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '1.125rem',
              textAlign: 'right',
            })}>
            <a href="https://www.thewebkitchen.co.uk/">Web design by TWK</a>
          </div>
        </Cell>
      </Grid>
    </div>
  );
}
